import TrashIcon from "./icons/TrashIcon";

function LinkCard(props) {
  const handleClick = () => {
    if (props.onClick) props.onClick();
    window.open(props.link, "_blank");
  }

  return (
    <div className="sm:max-w-[840px] mx-auto py-4 flex flex-row w-full h-fit items-center space-x-1">
      <div
        onClick={handleClick}
        className="btn bg-white sm:px-16 py-4 flex flex-row shadow sm:text-2xl flex-1 h-fit rounded-full"
      >
        {props.photo &&
          <img src={props.photo} className="rounded h-20" alt="Movie"/>
        }
        <div className="normal-case font-normal mx-auto">
          {props.title}
        </div>
      </div>
    </div>
  );
}

export default LinkCard;

