import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faXTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const SocialBar = (props) => {

  // Social media links
  const socialMediaLinks = {
    facebook: "https://www.facebook.com/" + props.socials?.facebook,
    twitter: "https://x.com/" + props.socials?.twitter,
    instagram: "https://www.instagram.com/" + props.socials?.instagram,
    linkedin: "https://www.linkedin.com/in/" + props.socials?.linkedin,
  };

  return (
    <div className={props.className}>
      {props.socials?.facebook &&
        <a href={socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="2x" style={{ margin: "0.5rem" }} />
        </a>
      }
      {props.socials?.twitter &&
        <a href={socialMediaLinks.twitter} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} size="2x" style={{ margin: "0.5rem" }} />
        </a>
      }
      {props.socials?.instagram &&
        <a href={socialMediaLinks.instagram} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" style={{ margin: "0.5rem" }} />
        </a>
      }
      {props.socials?.linkedin &&
        <a href={socialMediaLinks.linkedin} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" style={{ margin: "0.5rem" }} />
        </a>
      }
    </div>
  );
};

export default SocialBar;

