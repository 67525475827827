import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";
import CopyIcon from "./icons/CopyIcon";
import EditIcon from "./icons/EditIcon";
import LogoutIcon from "./icons/LogoutIcon";

function Drawer() {
  const {logout, currentUser} = useAuth();
  const navigate = useNavigate();

  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href).then(function() {
      alert("Link copied to clipboard!");
    });
  }

  const handleSignOutClick = async () => {
    const success = await logout();
    if (success) {
      navigate("/");
    }
  }

  return (
    <div className="z-30 drawer drawer-primary drawer-end">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="ml-auto drawer-content">
        {/* Page content here */}
        <label htmlFor="my-drawer-4" className="bg-transparent border-none drawer-button btn">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
          </svg>

        </label>
      </div> 
      <div className="drawer-side">
        <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
        <ul className="flex flex-col space-y-3 items-start px-8 p-4 w-80 min-h-full bg-base-200 text-base-content">
          {/* Sidebar content here */}
          <li
            onClick={handleCopyClick}
            className="flex flex-row space-x-1 cursor-pointer"
          >
            <CopyIcon/>
            <span>Share link to this Causy</span>
          </li>
          {currentUser ?
            <li className="flex flex-row space-x-1 cursor-pointer">
              <EditIcon/>
              <a href="/admin">Edit my Causy</a>
            </li> :
            <li>
              <a className="btn btn-primary" href="/">
                Create your own Causy
              </a>
            </li>
          }
          {currentUser &&
            <li
              className="flex flex-row space-x-1 cursor-pointer"
              onClick={handleSignOutClick}
            >
              <LogoutIcon/>
              <span>Log out</span>
            </li>
          }
        </ul>
      </div>
    </div> 
  );
}

export default Drawer;
