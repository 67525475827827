import PersonIcon from "./icons/PersonIcon";

function Mockup(props) {
  return (
    <div className="text-xs mockup-phone border-gray-200 border-3">
      <div className="camera"></div>
      <div className="display">
        <div className="artboard flex flex-col justify-start pt-20 items-center px-4 overflow-y-auto bg-gradient-to-b from-yellow-100 to-pink-100 artboard-demo phone-1">
          <div className="bg-white rounded-lg w-full flex flex-col justify-center items-center border-4 border-red-200 p-4 text-center mb-4">
            {/* Profile picture */}
            {props.photo ?
              <div className="avatar">
                <div className="rounded-full w-20">
                  <img alt="this user's profile" src={props.photo}/>
                </div>
              </div> :
              <div className="rounded-full bg-gray-100 h-20 w-20 items-center flex justify-center">
                <PersonIcon/>
              </div>
            }
            <div className="font-bold mt-1">@{props.username}</div>
            <div className="text-sm">{props.bio}</div>
          </div>

          <div className="bg-white rounded-lg w-full flex flex-col justify-center items-center border-4 border-red-200 p-4 text-center mb-4">
            <div className="text-xs">
              <div className="uppercase font-bold text-center">💛 My cause 💛</div>
              <div>{props.cause}</div>
            </div>
          </div>
          
          <div className="font-barlow text-lg uppercase mb-2">Join my cause</div>
          {props.links && props.links.map((link, index) =>
            <a href={link.link} target="_blank" key={index} className="bg-white rounded-full mb-3 w-full text-center text-sm p-2">
              {link.title}
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default Mockup;

