import {useState, useEffect} from "react";

function EditLinkModal(props) {
  const [link, setLink] = useState(props.links && props.linkIndex < props.links.length ? props.links[props.linkIndex]?.link : "");
  const [title, setTitle] = useState(props.links && props.linkIndex < props.links.length ? props.links[props.linkIndex]?.title : "");

  useEffect(() => {
    setLink(props.links && props.linkIndex < props.links.length ? props.links[props.linkIndex]?.link : "");
    setTitle(props.links && props.linkIndex < props.links.length ? props.links[props.linkIndex]?.title : "");
  }, [props.linkIndex, props.links]);

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch(e) {
      return false;
    }
  }

  const handleSubmit = () => {
    if (!isValidUrl(link) || !title) {
      alert("Please make sure this is a valid link and title.");
      return;
    }

    if (props.onSubmit) {
      props.onSubmit(props.linkIndex, {
        link: link,
        title: title,
      });
    }
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  }

  return (
    <div className="modal-box flex flex-col space-y-2 w-full">
      <h1 className="font-bold">Edit your link</h1>
      <label>Title</label>
      <input
        onChange={handleTitleChange}
        value={title}
        type="text"
        placeholder="Support Causy"
        className="input input-bordered input-primary w-full"
      />

      <label>Link</label>
      <input
        value={link}
        onChange={handleLinkChange}
        type="text"
        placeholder="https://causy.me"
        className="input input-bordered input-primary w-full"
      />
      <button
        onClick={handleSubmit}
        className="w-fit mx-auto mt-2 btn btn-primary"
      >
        Submit
      </button>
    </div>
  );
}

export default EditLinkModal;

