import {useEffect} from "react";
import {useMixpanel} from 'react-mixpanel-browser';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";
import {useState} from "react";
import {addProfile, getProfilesByCreatorID} from "../utils/FirebaseAPI";
import SpinnerIcon from "../components/icons/SpinnerIcon";
import Toolbar from "../components/Toolbar";

function CreateProfileView() {
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const [username, setUsername] = useState();
  const [isLoading, setIsLoading] = useState(false);

  console.log(currentUser.photoURL);

  useEffect(() => {
    const checkIfProfileAlreadyCreated = async () => {
      setIsLoading(true);
      const result = await getProfilesByCreatorID(currentUser.uid);
      if (result.success && result.responseObject.length > 0) {
        navigate("/admin");
      } else {
        console.log("Error: ", result.error);
      }
      setIsLoading(false);
    }
    checkIfProfileAlreadyCreated();
  }, [currentUser, navigate]);

  useEffect(() => {
    if (mixpanel.config && mixpanel.config.token) {
      mixpanel.time_event('Page view');
      mixpanel.track('Page view', {
        'pageName': "Create profile",
        'userID': currentUser?.uid,
      });
    }

    // Track time when user leaves
    return () => {
      if (mixpanel.config && mixpanel.config.token) {
        mixpanel.time_event('Page view');
      }
    };
  }, [currentUser, mixpanel]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const profile = {
      displayName: currentUser.displayName,
      photoURL: currentUser.photoURL,
      isPublished: false,
      creatorID: currentUser.uid,
      numLinkClicks: 0,
    }
    const result = await addProfile(username, profile);
    if (result.success) {
      if (mixpanel.config && mixpanel.config.token) {
        mixpanel.track('Profile created', {
          'userID': currentUser?.uid,
          'username': username,
        });
      }
      navigate("/admin");
    } else {
      alert("Sorry, something went wrong. Please make sure all the fields are filled out or try a new username.");
    }
    setIsLoading(false);
  }

  return (
    <div>
      {currentUser &&
        <div>
          <Toolbar hideViewMyCausy photo={currentUser?.photoURL}/>
          <div className="flex flex-col p-24 space-y-4">
            <header className="text-xl font-bold">Welcome to Causy!</header>
            <div className="text-lg">Choose your Causy username.</div>
            <div className="flex flex-row items-center space-x-2">
              <span>causy.com/</span>
              <input
                onChange={(e) => setUsername(e.target.value)}
                type="text"
                placeholder="username"
                className="input input-bordered input-primary w-full max-w-xs"
              />
            </div>

            <button
              onClick={handleSubmit}
              className="btn btn-primary w-32"
            >
              {!isLoading ? <p>Submit</p> : <SpinnerIcon/>}
            </button>
          </div>
        </div>
      }
    </div>
  );
}

export default CreateProfileView;

