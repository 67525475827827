import {useEffect} from "react";
import {useMixpanel} from 'react-mixpanel-browser';
import {useAuth} from "../contexts/AuthContext";
import {useNavigate, Navigate} from "react-router-dom";
import GoogleSignInButton from "../components/GoogleSignInButton";
import Mockup from "../images/mockup.png";
import Logo from "../images/stamp-logo.png";

function LandingPageView() {
  const privacyPolicyURL = "https://drive.google.com/file/d/1osgMYzqOQuMDyLmcke2f7t2iTV05Dct2/view?usp=sharing";
  const termsURL = "https://drive.google.com/file/d/13fZDRk0UIx5Es_pe1PIZ0EJYuU2_-d8g/view?usp=sharing";
  const mixpanel = useMixpanel();
  const navigate = useNavigate();
  const {login, currentUser} = useAuth();

  useEffect(() => {
    if (mixpanel.config && mixpanel.config.token) {
      mixpanel.time_event('Page view');
      mixpanel.track('Page view', {
        'pageName': "Landing",
        'viewerUserID': currentUser?.uid,
      });
    }

    // Track time when user leaves
    return () => {
      if (mixpanel.config && mixpanel.config.token) {
        mixpanel.time_event('Page view');
      }
    };
  }, [currentUser, mixpanel]);

  // If user is already authenticated, navigate to menu
  if (currentUser !== null) {
    // user is authenticated
    console.log("user logged in, redirecting to menu");
    return <Navigate to="/admin"/>;
  }

  const handleSignInClick = async () => {
    if (mixpanel.config && mixpanel.config.token) {
      mixpanel.track('Sign in clicked', {
        'authProvider': "Google",
      });
    }
    const user = await login();
    if (user) {
      if (mixpanel.config && mixpanel.config.token) {
        mixpanel.track('Sign in success', {
          userID: user.uid,
        });
      }
      navigate("/admin");
    } else {
      if (mixpanel.config && mixpanel.config.token) {
        mixpanel.track('Sign in error');
      }
      alert("Sorry, something went wrong. Please try again.");
    }
  }

  return (
    <div className="w-full h-full flex flex-col items-center px-12 pt-12 min-h-screen bg-gradient-to-b from-[#D50CF6] to-[#656BFF]">
      {/* Toolbar */}
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-row space-x-2 items-center">
          <img className="w-12" src={Logo} alt="postage stamp of duck with sunglasses and a floating heart"/>
          <div className="text-2xl uppercase text-white font-bold font-display">Causy</div>
        </div>

        <div className="hidden md:block">
          <GoogleSignInButton
            onClick={handleSignInClick}
            text="Sign up or log in"
          />
        </div>
      </div>

      {/* Hero */}
      <div className="items-center flex flex-col lg:my-auto lg:flex-row lg:space-x-12 lg:pb-12">
        {/* Mockup */}
        <img className="w-full md:mt-10 md:w-2/3 lg:mt-4 lg:w-1/2 mt-4" src={Mockup} alt="mockup of profile with links to causes"/>

        {/* Slogan */}
        <div className="flex flex-col items-center justify-center">
          <div className="font-display text-white text-2xl my-6 text-center md:text-3xl md:mb-10">Every cause you stand for,<br/>in one shareable link.</div>

          <GoogleSignInButton
            onClick={handleSignInClick}
            text="Sign up with Google"
          />
        </div>
      </div>

      {/*<div>
        <div>Refer the world to causes you care about</div>
      </div>

      <div>
        <div>Get recognized for your referrals.</div>
      </div>

      <div>
        <div>Make a difference, one link at a time.</div>
      </div>*/}

      <div className="mx-auto w-fit text-xs mt-auto p-4 w-fulljustify-center text-gray-200 sm:text-sm flex flex-row space-x-4">
        <div>
          Copyright 2024 - Creashell LLC
        </div>
        <a href={privacyPolicyURL} target="_blank">Privacy Policy</a>
        <a href={termsURL} target="_blank">Terms of Service</a>
      </div>
    </div>
  );
}

export default LandingPageView;

