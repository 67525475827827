import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";
import Logo from "../images/stamp-logo.png";
import PersonIcon from "../components/icons/PersonIcon";

function Toolbar(props) {
  const {logout, currentUser} = useAuth();
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  }

  const handleMyCausyClick = () => {
    navigate("/" + props.username);
  }

  const handleSignOutClick = async () => {
    const success = await logout();
    if (success) {
      navigate("/");
    }
  }

  return (
    <header className="flex justify-between items-center p-4">
      <div className="navbar bg-base-100 shadow-lg rounded-lg">

      <div className="flex-1">
        <div
          className="cursor-pointer flex items-center"
          onClick={handleHomeClick}
        >
          <img
            className="h-8 w-8 mr-2"
            src={Logo}
            alt="Causy Logo"
          />
          <span className="uppercase font-display">Causy</span>
        </div>
      </div>

      {/* View my page */}
      {!props.hideViewMyCausy &&
        <button
          className="btn btn-primary mr-2"
          onClick={handleMyCausyClick}
        >
          View My Causy
        </button>
      }

      <div className="flex-none">
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            {/* TODO: Fix photo url and username stuff in general */}
            <div className="w-10 rounded-full">
              {props.photo && props.photo !== undefined ?
                <img alt="user profile" src={props.photo}/> :
                <div className="rounded-full bg-gray-100 h-10 w-10 items-center flex justify-center">
                  <PersonIcon/>
                </div>
              }
            </div>
          </label>
          <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
            <li>
              {/* Sign out */}
              <button
                className="cursor-pointer"
                onClick={handleSignOutClick}
              >
                Log out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </header>
  );
}

export default Toolbar;

