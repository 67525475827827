import {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import {onAuthStateChanged} from 'firebase/auth';
import {auth} from '../utils/FirebaseConfig';
import {
  loginWithGoogle as firebaseLogin,
  logout as firebaseLogout
} from '../utils/FirebaseAPI';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  const login = async () => {
    const result = await firebaseLogin();
    if (result.success) {
      setCurrentUser(result.responseObject);
      return result.responseObject;
    } else {
      console.log(result.error);
      return null;
    }
  }

  const logout = async () => {
    const result = await firebaseLogout();
    if (result.error) {
      console.log(result.error);
    }
    return result.success;
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // User authentication changed!
      // Passed user is either null (user just logged out)
      // or the new logged in user
      setCurrentUser(user);
      setLoading(false);
    })
  }, [])

  const value = {
    currentUser,
    login,
    logout,
    loading,
  };

  return (
    <AuthContext.Provider value={value}>
      { !loading && children }
    </AuthContext.Provider>
  );
}

