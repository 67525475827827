import {Navigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";
  
function ProtectedRoute(props) {
  const {currentUser, loading} = useAuth();
  if (loading) {
    return (<div>Loading...</div>);
  }
  if (!currentUser) {
    // user is not authenticated
    return <Navigate to="/"/>;
  }
  return props.children;
};

export default ProtectedRoute;

