import {initializeApp} from "firebase/app";
import {getFirestore} from "@firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAuth, GoogleAuthProvider} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBqLQ4CQ_bj_A24GcBAUc2QEHFlI3eoApg",
  authDomain: "causy-262ed.firebaseapp.com",
  projectId: "causy-262ed",
  storageBucket: "causy-262ed.appspot.com",
  messagingSenderId: "289764385097",
  appId: "1:289764385097:web:7147667dfdf769c745fa98",
  measurementId: "G-XSLV53XSL9"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const authProvider = new GoogleAuthProvider();
export const storage = getStorage(app);

