import {useMixpanel} from 'react-mixpanel-browser';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";
import {getProfile, incrementLinkClickCount} from "../utils/FirebaseAPI";
import ProfilePreview from "../components/ProfilePreview";
import SocialBar from "../components/SocialBar";
import CauseCard from "../components/CauseCard";
import PageNotFoundView from "./PageNotFoundView";
import Drawer from "../components/Drawer";
import LinkCard from "../components/LinkCard";
import SpinnerIcon from "../components/icons/SpinnerIcon";

function ProfileView() {
  const {currentUser} = useAuth();
  const {username} = useParams();
  const mixpanel = useMixpanel();
  const [profile, setProfile] = useState();
  const [numLinkClicks, setNumLinkClicks] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      const result = await getProfile(username);
      if (result.success && result.responseObject && result.responseObject.isPublished) {
        setProfile(result.responseObject);
        setNumLinkClicks(result.responseObject.numLinkClicks);
      } else {
        console.log("Error: ", result.error);
      }
      setIsLoading(false);
    }
    fetchProfile();
  }, [username]);

  useEffect(() => {
    if (mixpanel.config && mixpanel.config.token) {
      mixpanel.time_event('Profile view');
      mixpanel.track('Profile view', {
        'profileUsername': username,
        'viewerUserID': currentUser?.uid,
      });
    }

    // Track time when user leaves
    return () => {
      if (mixpanel.config && mixpanel.config.token) {
        mixpanel.time_event('Profile view');
      }
    };
  }, [username, currentUser, mixpanel]);

  const handleLinkClick = async () => {
    const result = await incrementLinkClickCount(username);
    if (result.success) {
      setNumLinkClicks(x => x + 1);
    } else {
      console.log("Error: ", result.error);
    }
  }

  return (
    <div className="bg-gradient-to-b from-yellow-100 to-pink-100 w-full min-h-screen h-full">
      {isLoading ?
        <div className="flex flex-col w-full h-full items-center justify-center pb-6">
          <div className="my-10">Loading this Causy</div>
          <SpinnerIcon/>
        </div> :
        <div className="h-full flex items-center justify-center">
          {profile ?
            <div className="sm:w-2/3 text-center mx-auto h-full flex flex-col items-center sm:py-12">
              <Drawer/>
              <ProfilePreview
                isVerified={profile.isVerified}
                photo={profile.photoURL}
                name={profile.displayName}
                username={profile.id}
                bio={profile.bio}
                cause={profile.cause}
                numJoined={profile.numJoined}
              />

              <CauseCard className="mt-4" cause={profile.cause}/>
              {/* Impact */}
              <div className="w-full font-barlow text-center mt-10 text-3xl text-black uppercase">
                Join my cause
              </div>
              {numLinkClicks && numLinkClicks > 0 ?
                <div className="mt-1 text-xl mb-5">
                  <b>{numLinkClicks.toLocaleString()}</b> {numLinkClicks !== 1 ? "clicks" : "click"} contributed
                </div> : null
              }

              {/* Links */}
              <div className="flex flex-col w-full px-6 sm:px-0">
                {profile.links && profile.links.map((link, index) =>
                  <LinkCard
                    title={link.title}
                    key={index}
                    link={link.link}
                    onClick={handleLinkClick}
                  />
                )}
              </div>
              <SocialBar className="my-8" socials={profile.socialUsernames}/>
            </div> :
            <PageNotFoundView/>
          }
        </div>
      }
    </div>
  );
}

export default ProfileView;

