import {AuthProvider} from "./contexts/AuthContext";
import {MixpanelProvider} from "react-mixpanel-browser";
import ProtectedRoute from "./components/ProtectedRoute";
import ProfileView from "./pages/ProfileView";
import EditProfileView from "./pages/EditProfileView";
import CreateProfileView from "./pages/CreateProfileView";
import PageNotFoundView from "./pages/PageNotFoundView";
import LandingPageView from "./pages/LandingPageView";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

function App() {
  const isDevMode = () => {
    const productionHost = "causy.me";
    const currentHost = window.location.hostname.toLowerCase();
    console.log("dev mode: ", !currentHost.includes(productionHost));
    return (!currentHost.includes(productionHost));
  }

  return (
    <MixpanelProvider
      token={
        isDevMode() === true ?
          process.env.REACT_APP_MIXPANEL_TOKEN_DEV :
          process.env.REACT_APP_MIXPANEL_TOKEN_PROD
      }
    >
      <AuthProvider>
        <Router>
          <Routes>
            <Route
              path="/create"
              element={
                <ProtectedRoute>
                  <CreateProfileView/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <EditProfileView/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/:username"
              element={
                <ProfileView/>
              }
            />
            <Route index element={<LandingPageView/>}/>
            <Route path="*" element={<PageNotFoundView/>}/>
          </Routes>
        </Router>
      </AuthProvider>
    </MixpanelProvider>
  );
}

export default App;

