import LinkIcon from "./icons/LinkIcon";
import TrashIcon from "./icons/TrashIcon";
import EditIcon from "./icons/EditIcon";

function EditableLink(props) {
  const handleEditClick = () => {
    if (props.onEditClick) props.onEditClick(props.linkIndex);
  }

  const handleDeleteClick = () => {
    if (props.onDeleteClick) props.onDeleteClick(props.linkIndex);
  }

  return (
    <div className="flex flex-row space-x-2 border-b-2 py-3 border-gray-100 items-center">
      <div className="mb-1 mr-auto">
        <div className="font-bold">{props.link.title}</div>
        <div className="flex flex-row space-x-1">
          <LinkIcon/>
          <a href={props.link.link} target="_blank">{props.link.link}</a>
        </div>
      </div>
      <button className="btn" onClick={handleEditClick}>
        <EditIcon/>
      </button>
      <button className="btn" onClick={handleDeleteClick}>
        <TrashIcon/>
      </button>
    </div>
  );
}

export default EditableLink;

