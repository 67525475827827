import {useState} from "react";

function AddLinkModal(props) {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch(e) {
      return false;
    }
  }

  const handleSubmit = () => {
    if (!isValidUrl(link) || !title) {
      alert("Please make sure this is a valid link and title.");
      return;
    }

    if (props.onSubmit) {
      props.onSubmit({
        link: link,
        title: title,
      });
      setTitle("");
      setLink("");
    }
  }

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  }

  return (
    <div className="modal-box flex flex-col space-y-2 w-full">
      <h1 className="font-bold">Add a link to your Causy</h1>
      <label>Title</label>
      <input
        onChange={handleTitleChange}
        value={title}
        type="text"
        placeholder="Donate to the American Red Cross"
        className="input input-bordered input-primary w-full"
      />

      <label>Link</label>
      <input
        onChange={handleLinkChange}
        value={link}
        type="text"
        placeholder="https://www.redcross.org/donate/donation.html/"
        className="input input-bordered input-primary w-full"
      />
      <button
        onClick={handleSubmit}
        className="w-fit mx-auto mt-2 btn btn-primary"
      >
        Submit
      </button>
    </div>
  );
}

export default AddLinkModal;

