import {useEffect} from "react";
import {useMixpanel} from 'react-mixpanel-browser';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";
import {useState} from "react";
import {updateProfile, getProfilesByCreatorID} from "../utils/FirebaseAPI";
import SpinnerIcon from "../components/icons/SpinnerIcon";
import LinkIcon from "../components/icons/LinkIcon";
import EditableLink from "../components/EditableLink";
import EditPhotoIcon from "../components/icons/EditPhotoIcon";
import EditIcon from "../components/icons/EditIcon";
import ImageUploader from "../components/ImageUploader";
import AddLinkModal from "../components/AddLinkModal";
import EditLinkModal from "../components/EditLinkModal";
import Toolbar from "../components/Toolbar";
import LinkCard from "../components/LinkCard";
import Mockup from "../components/Mockup";
import ProfilePreview from "../components/ProfilePreview";

function EditProfileView() {
  const FIRESTORE_BUCKET_PATH = "profileImages/"
  const addLinkModalID = "add-link-modal";
  const editLinkModalID = "edit-link-modal";
  const uploadImageModalID = "upload-image-modal";
  const {currentUser} = useAuth();
  const navigate = useNavigate();
  const mixpanel = useMixpanel();
  const [originalProfile, setOriginalProfile] = useState();

  const [selectedLinkIndexToEdit, setSelectedLinkIndexToEdit] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [bio, setBio] = useState("");
  const [cause, setCause] = useState("");
  const [links, setLinks] = useState([]);

  const [facebookUsername, setFacebookUsername] = useState("");
  const [twitterUsername, setTwitterUsername] = useState("");
  const [instagramUsername, setInstagramUsername] = useState("");
  const [linkedinUsername, setLinkedinUsername] = useState("");

  useEffect(() => {
    const fetchProfile = async () => {
      setIsLoading(true);
      const result = await getProfilesByCreatorID(currentUser.uid);
      if (result.success) {
        if (result.responseObject.length === 0) {
          navigate("/create");
        } else {
          // Take the first one since assuming only
          // one profile per user for now
          const profile = result.responseObject[0];
          setOriginalProfile(profile);
          setDisplayName(profile.displayName);
          setPhotoURL(profile.photoURL);
          setBio(profile.bio ? profile.bio : "");
          setCause(profile.cause ? profile.cause : "");
          setLinks(profile.links ? profile.links: []);
          setFacebookUsername(profile.socialUsernames?.facebook);
          setInstagramUsername(profile.socialUsernames?.instagram);
          setLinkedinUsername(profile.socialUsernames?.linkedin);
          setTwitterUsername(profile.socialUsernames?.twitter);
        }
      } else {
        console.log("Error: ", result.error);
      }
      setIsLoading(false);
    }
    fetchProfile();
  }, [navigate, currentUser]);

  useEffect(() => {
    if (mixpanel.config && mixpanel.config.token) {
      mixpanel.time_event('Page view');
      mixpanel.track('Page view', {
        'pageName': "Edit profile",
        'userID': currentUser?.uid,
      });
    }

    // Track time when user leaves
    return () => {
      if (mixpanel.config && mixpanel.config.token) {
        mixpanel.time_event('Page view');
      }
    };
  }, [currentUser, mixpanel]);

  const handleSubmit = async () => {
    setIsLoading(true);

    const profile = {
      displayName: displayName,
      photoURL: photoURL,
      bio: bio,
      cause: cause,
      links: links,
      socialUsernames: {},
      isPublished: true,
    }

    if (facebookUsername) profile.socialUsernames.facebook = facebookUsername;
    if (twitterUsername) profile.socialUsernames.twitter= twitterUsername;
    if (instagramUsername) profile.socialUsernames.instagram = instagramUsername;
    if (linkedinUsername) profile.socialUsernames.linkedin = linkedinUsername;

    const result = await updateProfile(originalProfile.id, profile);
    if (result.success) {
      if (mixpanel.config && mixpanel.config.token) {
        mixpanel.track('Profile edited', {
          'userID': currentUser?.uid,
        });
      }
      navigate("/" + originalProfile.id);
    } else {
      alert("Sorry, something went wrong. Please make sure all the fields are filled out or try a new username.");
      console.log(result.error);
    }
    setIsLoading(false);
  }

  const handleImageUploadSuccess = (newPhotoURL) => {
    setPhotoURL(newPhotoURL);
    document.getElementById(uploadImageModalID).checked = false;
  }

  const handleAddLink = (newLinkObject) => {
    var newLinks = [newLinkObject];
    if (links && links.length > 0) {
      newLinks = links.concat(newLinks);
    }
    setLinks(newLinks);
    document.getElementById(addLinkModalID).checked = false;
  }

  // Toggles on edit modal
  const handleEditLinkClick = (indexToEdit) => {
    setSelectedLinkIndexToEdit(indexToEdit);
    document.getElementById(editLinkModalID).checked = true;
  };

  // Actually updates state links array with new edits
  const handleEditLink = (indexToChange, updatedLink) => {
    const updatedLinks = [...links];
    updatedLinks[indexToChange] = updatedLink;
    setLinks(updatedLinks);
    document.getElementById(editLinkModalID).checked = false;
  };

  const handleDeleteLink = (indexToDelete) => {
    const updatedLinks = links.filter((item, index) => index !== indexToDelete);
    setLinks(updatedLinks);
  };

  const handleCauseChange = (e) => {
    setCause(e.target.value);
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const handleInstagramUsernameChange = (e) => {
    setInstagramUsername(e.target.value);
  };

  const handleLinkedinUsernameChange = (e) => {
    setLinkedinUsername(e.target.value);
  };

  const handleTwitterUsernameChange = (e) => {
    setTwitterUsername(e.target.value);
  };

  const handleFacebookUsernameChange = (e) => {
    setFacebookUsername(e.target.value);
  };

  return (
    <div className="w-full min-h-screen h-full bg-gray-100">
      {originalProfile &&
        <div className="w-full h-full">
          <Toolbar hideViewMyCausy={!originalProfile.isPublished} username={originalProfile.id} photo={originalProfile.photoURL}/>
          <div className="flex flex-col p-8 w-full mx-auto md:w-2/3 space-y-4 justify-center">
            <header className="text-xl font-bold font-display text-center">
              Edit your Causy
            </header>

            <div className="flex flex-col md:flex-row md:space-x-8 md:items-start justify-center items-center">
              {/* Settings */}
              <div className="flex w-full flex-col space-y-2">

                <div className="flex flex-col space-y-2 border-b-2 shadow-lg p-6 rounded-xl bg-white">
                  <header className="text-lg w-full font-display">
                    Profile Card
                  </header>

                  <div className="w-full flex flex-col space-y-2">
                    <label className="font-bold">
                      Profile photo
                    </label>
                    <label
                      htmlFor={uploadImageModalID}
                      className="btn w-fit"
                    >
                      <EditPhotoIcon/> Change profile photo

                    </label>

                    <label className="font-bold">
                      Cause Statement
                    </label>
                    <input
                      onChange={handleCauseChange}
                      value={cause}
                      type="text"
                      placeholder="I care about climate change."
                      className="input input-bordered input-primary w-full max-w-xs"
                    />

                    <label className="font-bold">
                      Bio
                    </label>
                    <input
                      onChange={handleBioChange}
                      value={bio}
                      type="text"
                      placeholder="Software engineer at Tesla"
                      className="input input-bordered input-primary w-full max-w-xs"
                    />
                  </div>


                </div>

                {/* Add links */}
                <div className="flex flex-col space-y-2 border-b-2 shadow-lg p-6 rounded-xl bg-white">
                  <header className="text-lg w-full font-display">
                    Cause Links
                  </header>

                  {/* Add link button */}
                  <label
                    htmlFor={addLinkModalID}
                    className="mt-4 btn w-fit"
                  >
                    + Add new link
                  </label>
                  {links && links.map((link, index) => (
                    <EditableLink
                      key={index}
                      linkIndex={index}
                      link={link}
                      onDeleteClick={handleDeleteLink}
                      onEditClick={handleEditLinkClick}
                    />
                  ))}
                </div>

                {/* Add social links */}
                <div className="flex flex-col space-y-2 border-b-2 shadow-lg p-6 rounded-xl bg-white">
                  <header className="text-lg w-full font-display">
                    Social Links
                  </header>

                  {/*<div className="flex flex-row items-center space-x-1">
                    <LinkIcon/>
                    <div className="text-xs">facebook.com/</div>
                    <input
                      onChange={handleFacebookUsernameChange}
                      value={facebookUsername}
                      type="text"
                      placeholder="Facebook Username"
                      className="input-xs input input-bordered input-primary w-full"
                    />
                  </div>*/}

                  <div className="flex flex-row items-center space-x-1">
                    <LinkIcon/>
                    <div className="text-xs">instagram.com/</div>
                    <input
                      onChange={handleInstagramUsernameChange}
                      value={instagramUsername}
                      type="text"
                      placeholder="Instagram Username"
                      className="input-xs input input-bordered input-primary w-fit"
                    />
                  </div>

                  <div className="flex flex-row items-center space-x-1">
                    <LinkIcon/>
                    <div className="text-xs">linkedin.com/in/</div>
                    <input
                      onChange={handleLinkedinUsernameChange}
                      value={linkedinUsername}
                      type="text"
                      placeholder="LinkedIn Username"
                      className="input-xs input input-bordered input-primary w-fit"
                    />
                  </div>

                  <div className="flex flex-row items-center space-x-1">
                    <LinkIcon/>
                    <div className="text-xs">x.com/</div>
                    <input
                      onChange={handleTwitterUsernameChange}
                      value={twitterUsername}
                      type="text"
                      placeholder="X Username"
                      className="input-xs input input-bordered input-primary w-fit"
                    />
                  </div>
                </div>

              </div>

              <div className="mt-4 md:mt-0 px-6 py-4 rounded-xl">
                <header className="text-center font-display text-lg mb-2 font-bold">Preview</header>
                <Mockup
                  bio={bio}
                  links={links}
                  photo={photoURL}
                  username={originalProfile.id}
                  name={displayName}
                  cause={cause}
                />
              </div>
            </div>


            <button
              onClick={handleSubmit}
              className="mt-4 btn btn-primary w-fit mx-auto"
            >
              {!isLoading ? <p>Save changes</p> : <SpinnerIcon/>}
            </button>
          </div>

          {/* Add link modal */}
          <input type="checkbox" id={addLinkModalID} className="modal-toggle" />
          <div id={addLinkModalID} className="modal modal-middle">
            <AddLinkModal onSubmit={handleAddLink}/>
            <label className="modal-backdrop" htmlFor={addLinkModalID}>Close</label>
          </div>

          {/* Edit link modal */}
          <input type="checkbox" id={editLinkModalID} className="modal-toggle" />
          <div id={editLinkModalID} className="modal modal-middle">
            <EditLinkModal onDelete={handleDeleteLink} links={links} linkIndex={selectedLinkIndexToEdit} onSubmit={handleEditLink}/>
            <label className="modal-backdrop" htmlFor={editLinkModalID}>Close</label>
          </div>

          {/* Upload image modal */}
          <input type="checkbox" id={uploadImageModalID} className="modal-toggle" />
          <div id={uploadImageModalID} className="modal modal-middle">
            <div className="modal-box">
              <div className="font-bold mb-2">Upload new profile picture</div>
              <ImageUploader
                onSuccess={handleImageUploadSuccess}
                bucketPath={FIRESTORE_BUCKET_PATH}
              />
            </div>
            <label className="modal-backdrop" htmlFor={uploadImageModalID}>Close</label>
          </div>

        </div>
      }
    </div>
  );
}

export default EditProfileView;

