import {v4} from "uuid";
import {auth, authProvider, db, storage} from "./FirebaseConfig";
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import {signInWithPopup, signOut} from "firebase/auth";
import {increment, doc, collection, query, where, getDoc, getDocs, setDoc} from 'firebase/firestore';
// TODO: Check auth to look appropriate calls

/* Authentication */
export const loginWithGoogle = async () => {
  try {
    if (auth.currentUser !== null) {
      throw new Error("User already logged in.");
    }
    const result = await signInWithPopup(auth, authProvider);
    return {success: true, responseObject: result.user};
  } catch (error) {
    return {success: false, error: error.message};
  }
}

export const logout = async () => {
  try {
    if (auth.currentUser === null) {
      throw new Error("No user logged in.");
    }
    await signOut(auth);
    return {success: true};
  } catch (error) {
    return {success: false, error: error.message};
  }
}

// TODO: Edit to actually check firebase
const isUsernameAvailable = async (username) => {
  const RESTRICTED_PATHS = ["admin", "create"];
  if (username in RESTRICTED_PATHS) return false;
  const profileRef = doc(db, "profiles", username);
  const profileResult = await getDoc(profileRef);
  return !(profileResult.exists());
}

/* Create profile */
// TODO: Enforce only one profile per user for now
export const addProfile = async (username, profile) => {
  /*if (auth.currentUser === null) {
    if (onError) onError("Error: No user logged in.");
    return;
  }*/
  try {
    if (!username || !("displayName" in profile && "photoURL" in profile && "isPublished" in profile)) {
      throw new Error("Invalid profile");
    }
    const profileRef = doc(db, "profiles", username);
    const profilesMetadataRef = doc(db, "metadata", "profiles");
    if (await isUsernameAvailable(username)) {
      const profilesMetadataResult = await getDoc(profilesMetadataRef);
      // Fetch what number profile this is (1-indexed)
      // And update # joined to profile object
      const numProfiles = profilesMetadataResult.data().numProfiles;
      profile.numJoined = numProfiles + 1;

      // Add profile to profiles collection
      await setDoc(profileRef, profile);

      // Update profile added number
      await setDoc(profilesMetadataRef, {
        numProfiles: increment(1),
      }, {merge: true});

      return {success: true};
    } else {
      throw new Error("Username taken");
    }
  } catch (error) {
    return {success: false, error: error.message};
  }
}

/* Update profile */
export const updateProfile = async (profileID, updateParams) => {
  try {
    const profileRef = doc(db, "profiles", profileID);
    await setDoc(profileRef, updateParams, { merge: true });
    return {success: true};
  } catch (error) {
    return {success: false, error: error.message};
  }
}

/* Get profile */

// If no profile is found for username, returns
// result success as true but responseobject as null
export const getProfile = async (username) => {
  try {
    if (!username) {
      throw new Error("Invalid username");
    }
    const profileRef = doc(db, "profiles", username);
    const result = await getDoc(profileRef);
    const profile = result.exists() ? {...result.data(), id: result.id} : null;
    return {success: true, responseObject: profile};
  } catch (error) {
    return {success: false, error: error.message};
  }
}

// If no profiles are found for username, returns
// result success as true but responseobject as null
export const getProfilesByCreatorID = async (creatorID) => {
  try {
    if (!creatorID) {
      throw new Error("Invalid creatorID");
    }
    const profilesRef = collection(db, "profiles");
    var toFetch = query(profilesRef,
      where("creatorID", "==", creatorID),
    );
    const result = await getDocs(toFetch);
    const profiles = result.docs.map((doc) => ({...doc.data(), id: doc.id}));
    return {success: true, responseObject: profiles};
  } catch (error) {
    return {success: false, error: error.message};
  }
}


/* Links */
// Increment total link click count for a given profile
export const incrementLinkClickCount = async (username) => {
  try {
    if (!username) {
      throw new Error("Invalid username");
    }
    const profileRef = doc(db, "profiles", username);
    await setDoc(profileRef, {
      numLinkClicks: increment(1),
    }, {merge: true});
    return {success: true};
  } catch (error) {
    return {success: false, error: error.message};
  }
}

/* Images */
//TODO: Change to return result object like other calls, tried but
// got stuck on returning properly in callback
export const uploadImage = async (bucketPath, imageUpload, onSuccess, onError) => {
  try {
    if (imageUpload === null || !bucketPath) {
      throw new Error("Invalid parameters");
    }
    const documentPath = bucketPath + v4() + imageUpload.name;
    const imageRef = ref(storage, documentPath);
    await uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((downloadURL) => {
        if (onSuccess) onSuccess(downloadURL);
      });
    });
  } catch (error) {
    if (onError) onError(error.message);
  }
}

