import Logo from "../images/stamp-logo.png";

function PageNotFoundView() {
  return (
    <div className="p-24 flex flex-col items-center text-lg">
      <img alt="astronaut sitting on clouds" src={Logo} className="mb-4"/>
      <div>The page you're looking for doesn't exist.</div>
      <div><a className="link font-bold" href="/">Join Causy now</a> to claim this username!</div>
    </div>
  );
}

export default PageNotFoundView;

