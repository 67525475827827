function CauseCard(props) {
  return (
    <div className={props.className}>
      <div className="p-4 border-red-200 text-center text-black border-8 rounded-xl bg-white w-96">
        <div className="uppercase font-bold text-center">💛 My cause 💛</div>
        <div className="">{props.cause}</div>
      </div>
    </div>
  );
}

export default CauseCard;

