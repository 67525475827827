import {useState} from 'react';
import {uploadImage} from '../utils/FirebaseAPI';
import SpinnerIcon from "../components/icons/SpinnerIcon";

function ImageUploader(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [imagePreviewSource, setImagePreviewSource] = useState(null);

  const handleImageUpload = async () => {
    if (imageToUpload === null) return;

    const onImageUploadSuccess = (imageURL) => {
      if (props.onSuccess) {
        props.onSuccess(imageURL);
      }
    }

    const onImageUploadError = (error) => {
      if (props.onError) {
        props.onError(error);
      }
    }

    setIsLoading(true);
    await uploadImage(props.bucketPath, imageToUpload, onImageUploadSuccess, onImageUploadError);
    setIsLoading(false);
  }

  const onImageUploadChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const img = e.target.files[0];
      setImageToUpload(img);
      setImagePreviewSource(URL.createObjectURL(img));
    }
  }

  return (
    <div className="flex flex-col space-y-2 justify-start">
      <div className="flex flex-col">
        <input
          type="file"
          className="mb-2 file-input file-input-bordered file-input-primary w-full max-w-xs"
          onChange={(e) => {
            onImageUploadChange(e);
          }}
        />
        {imagePreviewSource &&
          <img
            src={imagePreviewSource}
            alt="preview of upload"
          />
        }
      </div>
      <button
        className={imageToUpload ? "btn btn-primary w-fit mx-auto visible" : "hidden"}
        onClick={handleImageUpload}
      >
        {!isLoading ? <p>Upload</p> : <SpinnerIcon/>}
      </button>
    </div>
  );
}

export default ImageUploader;
