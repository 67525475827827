function NumberJoinedBadge(props) {
  const getBadgeLabel = (rank) => {
    if (rank <= 10) {
      return "First 10 to join";
    } else if (rank <= 100) {
      return "First 100 to join";
    } else if (rank <= 1000) {
      return "First 1000 to join";
    } else if (rank <= 10000) {
      return "First 10,000 to join";
    } else if (rank <= 100000) {
      return "First 100,000 to join";
    } else if (rank <= 1000000) {
      return "First 1,000,000 to join";
    } else {
      return "Joined late but still fly 🐔";
    }
  };

  const badgeLabel = getBadgeLabel(props.rank);

  return (
    <div className="badge badge-primary" data-tip={badgeLabel}>
      {badgeLabel}
    </div>
  );
}

export default NumberJoinedBadge;

