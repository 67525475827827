{/*function GoogleSignInButton(props) {
  return (
    <button onClick={props.onClick} className="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-slate-700 dark:text-slate-200 hover:border-slate-400 dark:hover:border-slate-500 hover:text-slate-900 dark:hover:text-slate-300 hover:shadow transition duration-150 dark:bg-slate-900 bg-white">
      <img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo"/>
      <span>Sign up with Google</span>
    </button>
  );
}

export default GoogleSignInButton;
*/}

// Credits to https://tailwindflex.com/laurits/sign-up-with-google-button

function GoogleSignInButton(props) {
  return (
    <div
      className="px-6 sm:px-0 max-w-sm"
      onClick={props.onClick}
    >
      <button type="button" className="btn btn-main normal-case">
        <svg className="mr-2 -ml-1 w-4 h-4" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
          <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/>
        </svg>
        {props.text}
        <div></div>
      </button>
    </div>
  );
}

export default GoogleSignInButton;

