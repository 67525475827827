import VerifiedMark from "../images/verifiedCheckmark.png";
import EditPhotoIcon from "./icons/EditPhotoIcon";
import EditIcon from "./icons/EditIcon";
import RankBadge from "./RankBadge";
import NumberJoinedBadge from "./NumberJoinedBadge";

function ProfilePreview(props) {
  const wrapperClassName = "relative flex flex-col items-center p-4 rounded-2xl " + (!props.hideFrame ? "border-8 border-red-200 bg-white" : "") + (props.smaller ? " w-full" : " w-96");
  return (
    <div className={wrapperClassName}>
      {/* Profile photo */}
      <div className="relative w-fit flex flex-col">
        {/* TODO: Replace with total impact points overall */}
        {/*props.numJoined &&
          <RankBadge rank={props.numJoined}/>
        */}
        <div className="avatar">
          <div className={"rounded-full " + (props.smaller ? "w-20" : "w-36")}>
            <img alt="this user's profile" src={props.photo} />
          </div>
        </div>
      </div>

      {/* Username */}
      <div className="pt-2 text-2xl font-bold flex flex-row space-x-1 items-center">
        <span>@ {props.username}</span>
        {props.isVerified &&
          <img alt="blue verified checkmark" src={VerifiedMark} className="w-6 h-6"/>
        }
      </div>

      <div className="flex flex-col space-y-2 items-center text-center">
        {/* Bio */}
        <div className="">
          {props.bio}
        </div>

        {props.numJoined &&
          <NumberJoinedBadge rank={props.numJoined}/>
        }

      </div>
    </div>
  );
}

export default ProfilePreview;

